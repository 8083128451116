import React, { FunctionComponent } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { Stack } from '@templafy/ui/components/stack/Stack';
import { FlexBox } from '@templafy/ui/components/flexBox/FlexBox';
import { IconLeft } from '@templafy/ui/icons/IconLeft';
import { useTranslator } from '@templafy/translation';
import { useStaticQuery } from '@templafy/ui/hooks/dataFetchingHooks/useStaticQuery';
import { IconLoading } from '@templafy/ui/icons/IconLoading';
import { Paper } from '@templafy/ui/components/paper/Paper';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';
import { Api } from '../generated/Api';
import styles from './SelectTenantPage.module.scss';
import { DialogHeader } from './DialogHeader';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const SelectTenantPage: FunctionComponent = () => {
  const translator = useTranslator();
  const queryParameters = useQueryParameters();
  const email = queryParameters.get('email') ?? '';
  const redirectUri = queryParameters.get('redirect_uri') ?? undefined;
  const { state } = useStaticQuery({
    action: () =>
      Api.Users.searchAccessibleTenantsByEmail({
        email,
        redirectUri,
      }),
  });

  return (
    <AuthenticationLayout>
      <AuthenticationBox padding={0}>
        <DialogHeader
          title={translator.selectTenantPageSelectTenantDialogTitle()}
          subtitle={translator.selectTenantPageSelectTenantDialogSubtitle()}
        />
        <Stack
          flexGrow={1}
          gap="300"
          /* padding is necessary due to scroll bar */
          style={{ overflowY: 'auto', padding: '24px' }}
        >
          {state.status === 'loaded' ? (
            state.result.map((tenant) => {
              return (
                <SelectTenantCard
                  key={tenant.tenantId}
                  tenantName={tenant.tenantName}
                  tenantDomain={tenant.tenantDomain}
                  onClick={() => {
                    window.location.assign(tenant.signInUri);
                  }}
                />
              );
            })
          ) : (
            <FlexBox justifyContent="center" style={{ padding: 16 }}>
              <IconLoading />
            </FlexBox>
          )}
        </Stack>
      </AuthenticationBox>
    </AuthenticationLayout>
  );
};

type SelectTenantCardProps = {
  tenantName: string;
  tenantDomain: string;
  onClick: () => void;
};

const SelectTenantCard: FunctionComponent<SelectTenantCardProps> = ({
  onClick,
  tenantName,
  tenantDomain,
}) => {
  return (
    <Paper
      onClick={onClick}
      className={styles.card}
      shadow
      flexShrink={0}
      testId={tenantName}
    >
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        style={{ width: '100%' }}
      >
        <FlexBox flexDirection="column">
          <Text as="h3" size="200">
            {tenantName}
          </Text>

          <Text as="p" color="neutral-700" size="100">
            {tenantDomain}
          </Text>
        </FlexBox>
        <IconLeft rotation={180} size="small" />
      </FlexBox>
    </Paper>
  );
};
