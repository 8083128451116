import { FlexBox } from '@templafy/ui/components/flexBox/FlexBox';
import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react';
import { Illustration, IllustrationProps } from './illustration/Illustration';
import { Info } from './info/Info';
import { usePageOrientation } from '../../utilities/usePageOrientation';
import styles from './AuthenticationStandaloneLayout.module.scss';
import { TemplafyIcon } from './templafyIcon/TemplafyIcon';

interface AuthenticationStandaloneLayoutProps {
  title: string;
  backgroundImage: string;
}

type Props = AuthenticationStandaloneLayoutProps &
  Pick<IllustrationProps, 'landscapeImage' | 'portraitImage'> & {
    children: ReactNode;
  };

export type BaseAuthenticationStandaloneLayoutProps = Pick<
  Props,
  'backgroundImage'
>;

export const AuthenticationStandaloneLayout: FunctionComponent<Props> = ({
  title,
  children,
  backgroundImage,
  landscapeImage,
  portraitImage,
}) => {
  const isLandscape = usePageOrientation();
  return (
    <Layout backgroundImage={backgroundImage}>
      <div
        className={
          isLandscape
            ? styles.authenticationStandaloneLayoutLandscape
            : styles.authenticationStandaloneLayoutPortrait
        }
      >
        <TemplafyIcon />
        <Illustration
          landscapeImage={landscapeImage}
          portraitImage={portraitImage}
        />
        <Info title={title}>{children}</Info>
      </div>
    </Layout>
  );
};

interface LayoutProps {
  backgroundImage: string;
  children: ReactNode;
}

const onImageError = (event: SyntheticEvent<HTMLImageElement>) => {
  event.currentTarget.src = '';
};

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  backgroundImage,
}) => {
  return (
    <>
      <img
        src={backgroundImage}
        onError={onImageError}
        alt=""
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: -1,
          objectFit: 'cover',
        }}
      />
      <FlexBox
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        {children}
      </FlexBox>
    </>
  );
};
