import { FlexBox } from '@templafy/ui/components/flexBox/FlexBox';
import React, { FunctionComponent, ReactNode } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { usePageOrientation } from '../../../utilities/usePageOrientation';

interface InfoProps {
  title: string;
  children: ReactNode;
}

export const Info: FunctionComponent<InfoProps> = ({ title, children }) => {
  const isLandscape = usePageOrientation();
  return (
    <FlexBox
      flexDirection="column"
      flexShrink={isLandscape ? 1 : 0}
      alignItems={isLandscape ? 'flex-start' : 'center'}
      style={{
        padding: 20,
        textAlign: isLandscape ? 'start' : 'center',
        width: '100%',
      }}
    >
      <Text as="h3">{title}</Text>
      <FlexBox
        testId="content"
        style={{ marginTop: 14 }}
        flexDirection="column"
        alignItems={isLandscape ? 'flex-start' : 'center'}
        width="100%"
        flexShrink={0}
      >
        <div
          style={{ width: '100%', textAlign: isLandscape ? 'left' : 'center' }}
        >
          {children}
        </div>
      </FlexBox>
    </FlexBox>
  );
};
