/* eslint-disable */
// AUTOGENERATED FILE PLEASE DO NOT EDIT!!
import { injectLoaders } from '@templafy/translation';
import en from './language/en.json';

injectLoaders({
  en,
  da: () => import(/* webpackChunkName: "language_da" */ './language/da.json'),
  de: () => import(/* webpackChunkName: "language_de" */ './language/de.json'),
  es: () => import(/* webpackChunkName: "language_es" */ './language/es.json'),
  fr: () => import(/* webpackChunkName: "language_fr" */ './language/fr.json'),
  it: () => import(/* webpackChunkName: "language_it" */ './language/it.json'),
  ja: () => import(/* webpackChunkName: "language_ja" */ './language/ja.json'),
  nb: () => import(/* webpackChunkName: "language_nb" */ './language/nb.json'),
  nl: () => import(/* webpackChunkName: "language_nl" */ './language/nl.json'),
  pt: () => import(/* webpackChunkName: "language_pt" */ './language/pt.json'),
  sv: () => import(/* webpackChunkName: "language_sv" */ './language/sv.json'),
});
