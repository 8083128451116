import { useTranslator } from '@templafy/translation';
import { IconKeyDark } from '@templafy/ui/icons/IconKeyDark';
import React, { FunctionComponent } from 'react';
import { AuthenticationMethodDto } from '../../generated/Contracts';
import { AuthenticationMethodCardBase } from './AuthenticationMethodCardBase';

interface SingleSignOnAuthenticationMethodCardProps {
  authenticationMethod: AuthenticationMethodDto;
}

export const SingleSignOnAuthenticationMethodCard: FunctionComponent<
  SingleSignOnAuthenticationMethodCardProps
> = ({ authenticationMethod }) => {
  const translator = useTranslator();

  return (
    <AuthenticationMethodCardBase
      authenticationMethod={authenticationMethod}
      subtitle={translator.singleSignOnAuthenticationMethodCardSubtitle()}
      icon={IconKeyDark}
    />
  );
};
