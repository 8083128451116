import React, { FunctionComponent } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { IdentificationPage } from './components/IdentificationPage';
import { SelectAuthenticationMethodPage } from './components/SelectAuthenticationMethodPage';
import { EmailLoginPage } from './components/EmailLoginPage';
import {
  AuthenticationErrorPage,
  ERROR_TYPE,
} from './components/AuthenticationErrorPage';
import { SingleSignOnErrorPage } from './components/SingleSignOnErrorPage';
import { ErrorPage } from './components/standalonePages/ErrorPage';
import { LoggedOutPage } from './components/LoggedOutPage';
import { AccessDeniedPage } from './components/standalonePages/AccessDeniedPage';
import { UserActivationPage } from './components/UserActivationPage';
import { ForgotPasswordPage } from './components/ForgotPasswordPage';
import { ResetPasswordPage } from './components/ResetPasswordPage';
import { AcceptInvitePage } from './components/AcceptInvitePage';
import { ActivationLinkSentPage } from './components/ActivationLinkSentPage';
import { InviteExpiredPage } from './components/InviteExpiredPage';
import { ResetPasswordExpiredPage } from './components/ResetPasswordExpiredPage';
import { NotFoundPage } from './components/standalonePages/NotFoundPage';
import { PasswordResetLinkSentPage } from './components/PasswordResetLinkSentPage';
import { TenantIsNotAvailablePage } from './components/TenantIsNotAvailablePage';
import { SelectTenantPage } from './components/SelectTenantPage';
import { App } from './App';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<IdentificationPage />} />
      <Route
        path="/select-authentication-method"
        element={<SelectAuthenticationMethodPage />}
      />
      <Route path="/email/login" element={<EmailLoginPage />} />
      <Route
        path="/invalid-authentication"
        element={<AuthenticationErrorPage errorType={ERROR_TYPE.INVALID} />}
      />
      <Route
        path="/unexpected-authentication"
        element={<AuthenticationErrorPage errorType={ERROR_TYPE.UNEXPECTED} />}
      />
      <Route path="/single-sign-on-error" element={<SingleSignOnErrorPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/logged-out" element={<LoggedOutPage />} />
      <Route path="/access-denied" element={<AccessDeniedPage />} />
      <Route
        path="/activate-user/:activationId"
        element={<UserActivationPage />}
      />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route
        path="/activation-email-sent"
        element={<ActivationLinkSentPage />}
      />
      <Route path="/accept-invite/:inviteId" element={<AcceptInvitePage />} />
      <Route path="/invite-expired" element={<InviteExpiredPage />} />
      <Route
        path="/reset-password-expired"
        element={<ResetPasswordExpiredPage />}
      />
      <Route path="/not-found" element={<NotFoundPage />} />
      <Route
        path="/password-reset-link-sent"
        element={<PasswordResetLinkSentPage />}
      />
      <Route
        path="/password-reset-link-sent"
        element={<TenantIsNotAvailablePage />}
      />
      <Route path="/select-tenant" element={<SelectTenantPage />} />
    </Route>,
  ),
  {
    basename: process.env.PUBLIC_URL,
  },
);

export const Routes: FunctionComponent = () => (
  <RouterProvider router={router} />
);
