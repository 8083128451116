import React, { FunctionComponent, useState } from 'react';
import { useAsyncEffect } from '@templafy/ui/hooks/useAsyncEffect';
import { useTranslator } from '@templafy/translation';
import { Stack } from '@templafy/ui/components/stack/Stack';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';
import {
  AuthenticationMethodDto,
  AuthenticationMethodType,
} from '../generated/Contracts';
import { Api } from '../generated/Api';
import { EmailAuthenticationMethodCard } from './AuthenticationMethodCards/EmailAuthenticationMethodCard';
import { SingleSignOnAuthenticationMethodCard } from './AuthenticationMethodCards/SingleSignOnAuthenticationMethodCard';
import { DialogHeader } from './DialogHeader';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const SelectAuthenticationMethodPage: FunctionComponent = () => {
  const queryParameters = useQueryParameters();
  const tenantId = queryParameters.get('tenantId') ?? '';
  const translator = useTranslator();

  const [authenticationMethods, setAuthenticationMethods] = useState<
    AuthenticationMethodDto[]
  >([]);

  const fetchAuthenticationMethods = async () => {
    setAuthenticationMethods(await Api.AuthenticationMethods.getAll(tenantId));
  };

  useAsyncEffect(async () => {
    await fetchAuthenticationMethods();
  }, []);

  return (
    <AuthenticationLayout>
      <AuthenticationBox padding={0}>
        <DialogHeader
          title={translator.selectAuthenticationMethodPageAuthenticationBoxTitle()}
          subtitle={translator.selectAuthenticationMethodPageAuthenticationBoxSubtitle()}
        />
        <Stack
          flexGrow={1}
          gap="300"
          /* padding is necessary due to scroll bar */
          style={{ overflowY: 'auto', padding: '24px' }}
        >
          {authenticationMethods.map((method) => {
            return method.type === AuthenticationMethodType.Email ? (
              <EmailAuthenticationMethodCard authenticationMethod={method} />
            ) : (
              <SingleSignOnAuthenticationMethodCard
                authenticationMethod={method}
              />
            );
          })}
        </Stack>
      </AuthenticationBox>
    </AuthenticationLayout>
  );
};
