import React from 'react';
import './index.scss';
import { UnauthenticatedResponseInterceptor } from '@templafy/request-service/responseInterceptors/UnauthenticatedResponseInterceptor';
import { createRoot } from 'react-dom/client';
import { requestService } from './generated/Api';
import { NotificationResponseInterceptor } from './utilities/NotificationResponseInterceptor';
import { Routes } from './Routes';

requestService.registerInterceptor(new NotificationResponseInterceptor());
// Clear unauthenticated response interceptor because we want to manually handle 401's in authentication SCS
requestService.removeInterceptor(UnauthenticatedResponseInterceptor);

const container = document.querySelector('#root');
const root = createRoot(container!);
root.render(<Routes />);
