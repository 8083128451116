import React, { FunctionComponent } from 'react';
import { useTranslator } from '@templafy/translation';
import { Text } from '@templafy/ui/components/text/Text';

interface OperationTextProps {
  id?: string;
}

export const OperationText: FunctionComponent<OperationTextProps> = ({
  id,
}) => {
  const translator = useTranslator();

  if (!id) {
    return null;
  }

  return (
    <Text
      testId="operation"
      size="100"
      color="gray-700"
      style={{ marginTop: 14, display: 'block' }}
    >
      {translator.operationId({ operationId: id })}
    </Text>
  );
};
