import React, { FunctionComponent } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { useTranslator } from '@templafy/translation';
import { Button } from '@templafy/ui/components/button/Button';
import unexpectedAuthenticationImage from '../resources/images/unexpected_authentication.png';
import backgroundImage from '../resources/images/background.png';
import { goToRoot } from '../utilities/navigation';
import { OperationText } from './OperationText';
import { AuthenticationStandaloneLayout } from './standaloneLayout/AuthenticationStandaloneLayout';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const ERROR_TYPE = {
  INVALID: 'INVALID',
  UNEXPECTED: 'UNEXPECTED',
} as const;

type AuthenticationErrorPageProps = {
  errorType: (typeof ERROR_TYPE)[keyof typeof ERROR_TYPE];
};

export const AuthenticationErrorPage: FunctionComponent<
  AuthenticationErrorPageProps
> = ({ errorType }) => {
  const queryParameters = useQueryParameters();
  const operationId = queryParameters.get('operationId') ?? '';
  const loginUrl = queryParameters.get('loginUrl') ?? '';

  const translator = useTranslator();

  const title =
    errorType === ERROR_TYPE.INVALID
      ? translator.invalidAuthenticationTitle()
      : translator.unexpectedAuthenticationTitle();

  const messsage =
    errorType === ERROR_TYPE.INVALID
      ? translator.invalidAuthenticationMessage()
      : translator.unexpectedAuthenticationMessage();

  return (
    <AuthenticationStandaloneLayout
      title={title}
      backgroundImage={backgroundImage}
      landscapeImage={unexpectedAuthenticationImage}
      portraitImage={unexpectedAuthenticationImage}
    >
      <Text as="p">{translator.invalidAuthenticationMessage()}</Text>

      <OperationText id={operationId} />

      <Button
        testId="button"
        action="primary"
        onClick={() => {
          if (loginUrl) {
            window.location.href = loginUrl;
          } else {
            goToRoot();
          }
        }}
        style={{ marginTop: 14 }}
      >
        {messsage}
      </Button>
    </AuthenticationStandaloneLayout>
  );
};
