import React, { FunctionComponent } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { useTranslator } from '@templafy/translation';
import { Button } from '@templafy/ui/components/button/Button';
import loggedOutImage from '../resources/images/logged_out.png';
import backgroundImage from '../resources/images/background.png';
import { goToRoot } from '../utilities/navigation';
import { AuthenticationStandaloneLayout } from './standaloneLayout/AuthenticationStandaloneLayout';

export const LoggedOutPage: FunctionComponent = () => {
  const translator = useTranslator();
  return (
    <AuthenticationStandaloneLayout
      title={translator.loggedOutTitle()}
      backgroundImage={backgroundImage}
      landscapeImage={loggedOutImage}
      portraitImage={loggedOutImage}
    >
      <Text as="p">{translator.loggedOutMessage()}</Text>

      <Text as="p">{translator.loggedOutHelpMessage()}</Text>

      <Button
        testId="button"
        action="primary"
        onClick={goToRoot}
        style={{ marginTop: '8px' }}
      >
        {translator.loggedOutLoginAgain()}
      </Button>
    </AuthenticationStandaloneLayout>
  );
};
