import { NotificationService } from '@templafy/notifications';
import { getTranslator, unsafe_dynamicTranslate } from '@templafy/translation';
import { ResponseInterceptor } from '@templafy/request-service/responseInterceptors/ResponseInterceptorRequestService';
import { HttpError } from '@templafy/request-service/HttpError';

export interface NotificationResponseError {
  arguments: string | undefined;
  titleTranslationKey: string;
  descriptionTranslationKey: string;
}

export class NotificationResponseInterceptor implements ResponseInterceptor {
  // eslint-disable-next-line class-methods-use-this
  public onError(httpError: HttpError) {
    const error = httpError.body as NotificationResponseError | undefined;
    const translationArguments = error?.arguments || {};

    const translator = getTranslator();

    const title = unsafe_dynamicTranslate(
      error?.titleTranslationKey ?? '',
      translationArguments,
      translator.notificationRequestServiceDefaultFailedTitle(),
    ) as string;

    const description = unsafe_dynamicTranslate(
      error?.descriptionTranslationKey ?? '',
      translationArguments,
      translator.notificationRequestServiceDefaultFailedDescription(),
    ) as string;

    NotificationService.error({
      title,
      description,
    });
    return Promise.reject(httpError);
  }
}
