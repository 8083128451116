import * as React from 'react';
import { FunctionComponent } from 'react';
import { IconTemplafy } from '@templafy/ui/icons/IconTemplafy';
import { TemplafyLogoBrand } from '@templafy/ui/illustrations/TemplafyLogoBrand';
import { usePageOrientation } from '../../../utilities/usePageOrientation';
import styles from './TemplafyIcon.module.scss';

export const TemplafyIcon: FunctionComponent = () => {
  const isLandscape = usePageOrientation();
  return (
    <div
      className={
        isLandscape ? styles.templafyIconLandscape : styles.templafyIconPortrait
      }
    >
      {isLandscape ? (
        <IconTemplafy
          testId="iconTemplafy"
          className={styles.templafyIcon}
          size="small"
          theme="primary"
        />
      ) : (
        <TemplafyLogoBrand />
      )}
    </div>
  );
};
