import React from 'react';
import { createRoot } from 'react-dom/client';

const getAntiforgeryValue = () => {
  const xsrfCookieName = '__Host-Csrf-Token';

  for (const cookieSegment of document.cookie.split(/;\s*/)) {
    if (cookieSegment.includes(`${xsrfCookieName}=`)) {
      return decodeURIComponent(
        cookieSegment.replace(`${xsrfCookieName}=`, ''),
      );
    }
  }
};

export const submitForm = (
  path: string,
  parameters: { [key: string]: string | undefined },
) => {
  const root = createRoot(document.documentElement);

  root.render(
    <>
      <form
        ref={(node: HTMLFormElement) => {
          if (node) {
            node.submit();
          }
        }}
        method="post"
        action={path}
      >
        {Object.keys(parameters).map((key) => (
          <input type="hidden" name={key} value={parameters[key]} key={key} />
        ))}
        <input type="hidden" name="csrf-token" value={getAntiforgeryValue()} />
      </form>
    </>,
  );
};
