export const validatePassword = (password: string) => {
  if (password.length >= 12) {
    return true;
  }

  if (password.length < 8) {
    return false;
  }

  const rules: RegExp[] = [
    /\d/ /* number */,
    /[A-Z]/ /* uppercase */,
    /[a-z]/ /* lowercase */,
    /[^\s\w]/ /* non-word except space */,
  ];

  const validationResult = rules.map((r) => r.test(password));
  return validationResult.filter((r) => r === true).length >= 3;
};

export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[^\s\w]).{8,}$/; // 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 non-word except space
