import React, { FunctionComponent } from 'react';
import { useTranslator } from '@templafy/translation';
import { Text } from '@templafy/ui/components/text/Text';
import unexpectedAuthenticationImage from '../resources/images/unexpected_authentication.png';
import backgroundImage from '../resources/images/background.png';
import { OperationText } from './OperationText';
import { AuthenticationStandaloneLayout } from './standaloneLayout/AuthenticationStandaloneLayout';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const InviteExpiredPage: FunctionComponent = () => {
  const translator = useTranslator();
  const queryParameters = useQueryParameters();
  const operationId = queryParameters.get('operationId') ?? '';
  return (
    <AuthenticationStandaloneLayout
      title={translator.inviteExpiredPageActivationLinkExpiredMessage()}
      backgroundImage={backgroundImage}
      landscapeImage={unexpectedAuthenticationImage}
      portraitImage={unexpectedAuthenticationImage}
    >
      <OperationText id={operationId} />
      <Text>
        {translator.inviteExpiredPageActivationLinkExpiredSubMessage()}
      </Text>
    </AuthenticationStandaloneLayout>
  );
};
