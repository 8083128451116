import { useState, useEffect } from 'react';

const screenTabletSize = 756;

const checkIsLandscape = (size: number) => size >= screenTabletSize;

export const usePageOrientation = () => {
  const [isLandscape, setIsLandscape] = useState<boolean>(
    checkIsLandscape(window.innerWidth),
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(checkIsLandscape(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isLandscape;
};
